<template>
    <div class="container-fluid section-mb">
        <div class="row gx-1 align-items-center">

            <div class="col-12 col-md-6 mb-3 mb-md-0 pe-0 pe-md-3">
                <h2 class="title-mb" v-html="$t('home.sales-points.heading')"></h2>
                <p v-html="$t('home.sales-points.body')"></p>

                <router-link class="icon-link icon-link-hover fw-bold"
                   to="/about">
                    {{ $t('home.sales-points.button') }}
                    <svg class="bi"
                         width="32"
                         height="32"
                         fill="blue">
                        <use xlink:href="@/assets/bootstrap-icons.svg#arrow-right" />
                    </svg>
                </router-link>

            </div>
            
            <div class="col-12 col-sm-8 col-md-6">
                <img src="@/assets/home-about-1.jpg"
                     class="img-fluid">
            </div>

            <!-- <div class="col-6 col-md-3">
                <img src="@/assets/test-2.jpg"
                     class="img-fluid">
            </div> -->



        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables.scss";
@import "@/scss/abstracts/customBootstrap.scss";

.section-sales-points {
    padding: {
        top: $section-padding-top;
        bottom: $section-padding-bottom;
    }

    background-color: #ddd0c834;

}

.icon-link {
    color: $primary;
    text-decoration-color: $primary;
    text-underline-offset: 0.375rem;
}
</style>