export interface ICarouselItem {
    name: string;
    content: string;
    active: boolean;
}

export class CarouselItem implements ICarouselItem {
    name: string;
    content: string;
    active: boolean;
    
    constructor(name: string, content: string, active?: boolean) {
        this.name = name;
        this.content = content;
        this.active = active ?? false;
    }
}