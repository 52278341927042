import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import './scss/styles.scss'
import cultureManager, {Culture} from "@/CultureManager";
import i18n from "@/i18n";
import registerDirectives from "@/directives";
import {createHead} from "@unhead/vue";

new Promise<void>((res, rej) => {
    (async () => {
        // API
        // const appSettings =
        //     process.env.NODE_ENV === 'production'
        //         ? ((await fetch('/settings')
        //             .then((response) => response.json())
        //             .catch((e) => {
        //                 throw `Unable to fetch app settings. Error: ${e as string}`
        //             })) as { ApiBaseUrl: string })
        //         : {ApiBaseUrl: 'http://localhost:5183'};
        // settings.init(appSettings.ApiBaseUrl)
        res();
    })();
}).then(() => {
    // API
    // const currentCulture = await new api.CulturesClient().currentCulture() as Culture;
    // cultureManager.init(currentCulture);

    cultureManager.initFromCookie()
    i18n.global.locale = cultureManager.currentCulture2letter;
}).then(async () => {
        const app = createApp(App);

        const head = createHead();
        app.use(head);
        
        app.use(router);
        app.use(i18n);
        registerDirectives(app);

        app.mount('#app')
    }
);
    // API
// }).then(async () => await new api.SessionClient().refresh());