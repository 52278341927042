import {App} from "vue";

export default function registerDirectives(app: App<Element>){
    app.directive("onResize", {
        mounted(el, binding) {
            const onResizeCallback = binding.value;
            window.addEventListener("resize", () => {
                const width = document.documentElement.clientWidth;
                const height = document.documentElement.clientHeight;
                onResizeCallback({ width, height });
            });
        },
        beforeUnmount(element, binding, vNode, prevVnode) {
            if ((binding.instance as any).unwatchHandlers) {
                (binding.instance as any).unwatchHandlers.filter((handler: { element: any }) => handler.element == element).forEach((handler: { unwatch: () => void }) => handler.unwatch());
                (binding.instance as any).unwatchHandlers = [];
            }
        },
    })
}