import et from "./locales/et.json";
import en from "./locales/en.json";

import {createI18n} from "vue-i18n";

export default createI18n({
    locale: "et",
    fallbackLocale: "et",
    messages: {
        et: et,
        en: en
    }
});
