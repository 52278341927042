import {api} from "@/api-client";
import CookieManager from "@/CookieManager";

export enum Culture {
    Estonian = "et-EE",
    English = "en-GB"
}

class CultureManager {
    private _currentCulture = "";
    
    public init(currentCulture : Culture) : void
    {
        this._currentCulture = currentCulture;
    }
    
    public initFromCookie() : void{
        const cookie = this.getCultureCookie();
        
        if (cookie === null) {
            this._currentCulture = "et-EE";
            return;
        }
        
        this._currentCulture = cookie;
    }
    
    get currentCulture() : Culture {
        switch (this._currentCulture) {
            case "en-GB":
                return Culture.English;
            case "et-EE":
            default:
                return Culture.Estonian;
        }
    }
    
    get currentCulture2letter() : "et" | "en" {
        switch (this._currentCulture) {
            case "en-GB":
                return "en";
            case "et-EE":
                return "et";
            default:
                return "et";
        }
    }
    
    get isEstonian() : boolean {
        return this.currentCulture === Culture.Estonian;
    }
    
    get isEnglish() : boolean {
        return this.currentCulture === Culture.English;
    }
    
    private getCultureCookie(): Culture | null {
        const cookie = CookieManager.getCookie('.lioi.culture')
        
        if (cookie === undefined) {
            return null;
        }
        
        return cookie as Culture;
    }
    private setCultureCookie(culture: Culture){
        CookieManager.setCookie('.lioi.culture', culture, new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
    }
    
    changeToEstonian() {
        this.setCultureCookie(Culture.Estonian);
        window.location.reload();
    }
    
    changeToEnglish() {
        this.setCultureCookie(Culture.English);
        window.location.reload();
    }
    
    // Api
    // async changeToEstonian() {
    //     await new api.CulturesClient().changeToEstonian();
    //     window.location.reload();
    // }
    //
    // async changeToEnglish() {
    //     await new api.CulturesClient().changeToEnglish();
    //     window.location.reload();
    // }
}

export default new CultureManager();