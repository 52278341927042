<template>
    <div class="section-background page-pt section-mb">
        <div class="container-fluid">
            <div class="row align-items-center">

                <div class="col"
                     style="margin-bottom: 10rem;">
                    <h1 class="text-secondary mt-5 mt-md-3 mt-lg-0"
                        v-html="$t('home.hero.heading')"></h1>
                    <p class="text-secondary subheading">
                        {{ $t('home.hero.body') }}
                    </p>
                    <router-link class="btn btn-primary"
                       to="/shop"
                       role="button">{{ $t('home.hero.button') }}</router-link>
                </div>

            </div>
        </div>
    </div>
</template>

<script setup
        lang="ts">

</script>

<style scoped
       lang="scss">

    .section-background {
        background: {
            image: url("@/assets/hero-1.jpg");
            size: cover;
            position: center;
        }
    }

    .row {

        @media (min-width: 1399.98px) {
            height: 60vh;
        }

        // `xxl` applies to x-large devices (large desktops, less than 1400px)
        @media (max-width: 1399.98px) {
            height: 60vh;
            margin-right: 15rem;
        }

        // `xl` applies to large devices (desktops, less than 1200px)
        @media (max-width: 1199.98px) {
            height: 60vh;
            margin-right: 15rem;
        }

        // `lg` applies to medium devices (tablets, less than 992px)
        @media (max-width: 991.98px) {
            height: 60vh;
            margin-right: 15rem;
        }

        // `md` applies to small devices (landscape phones, less than 768px)
        @media (max-width: 767.98px) {
            height: 50vh;
            margin-right: 10rem;
        }

        // `sm` applies to x-small devices (portrait phones, less than 576px)
        @media (max-width: 575.98px) {
            height: 50vh;
            margin-right: 7rem;
        }
    }

    .subheading {
        font-size: 0.9rem;
    }
</style>
