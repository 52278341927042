export default class CookieManager{
    public static getCookie(cookieName: string): string | null {
        const cookie = document.cookie
            .split('; ')
            .find(row => row.startsWith(cookieName))
            ?.split('=')[1];
        
        return cookie === undefined ? null : cookie;
    }
    
    public static setCookie(cookieName: string, value: string, expiration: Date) : void {
        const encodedCookieName = encodeURIComponent(cookieName);
        const encodedValue = encodeURIComponent(value);
        document.cookie = `${encodedCookieName}=${encodedValue}; path=/; expires=${expiration.toUTCString()}; samesite=lax; domain=lioi.ee`;
    }
}