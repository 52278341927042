import CookieManager from "@/CookieManager";

class ConsentCookieManager {
    public getConsentCookie(): boolean | null {
        const cookie = CookieManager.getCookie('.lioi.consent');
        return cookie === "true";
    }
    
    public setConsentCookie() : void {
        CookieManager.setCookie('.lioi.consent', "true", new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
    }
}

export default new ConsentCookieManager();